export class Property {
  constructor(
    public id: number,
    public title: string,
    public desc: string,
    public year_build: string,
    public propertyType: string,
    public propertyStatus: string[],
    public city: string,
    public zipCode: string[],
    public neighborhood: string[],
    public street: string[],
    public location: Location,
    public formattedAddress: string,
    public features: string[],
    public featured: any,
    public priceDollar: Price,
    public priceEuro: Price,
    public bedrooms: number,
    public bathrooms: number,
    public garages: number,
    public area: Area,
    public yearBuilt: number,
    public ratingsCount: number,
    public ratingsValue: number,
    public additionalFeatures: AdditionalFeature[],
    public gallery: Gallery[],
    public plans: Plan[],
    public videos: Video[],
    public published: string,
    public lastUpdate: string,
    public ref_num: string,
    public period: string,
    public currency: string,
    public lng: any,
    public lat: any,
    public user_id: any,
    public images: [],
    public youtube_link: any,
    public payment_method: any,
    public landmark: any,
    public street_name: any,
    public floor: any,
    public create_date: any,
    public purpose: any,
    public furnishings: any,
    public size: any,
    public rooms: any,
    public tel: any,
    public property_type: any,
    public districts: any,
    public country: any,
    public property_view: any,
    public price: any,
    public address: any,
    public description_en: any,
    public title_en: any,
    public description: any,
    public created_at: any,
    public copied: any,
    public url_ar: any,
    public url: any,
    public amenities: Amenities[],
    public is_brocker: Broker[],
    public views: number
  ) {}
}
export class Area {
  constructor(public id: number, public value: number, public unit: string) {}
}
export class Amenities {
  constructor(public id: number, public name: string) {}
}
export class Broker {
  constructor(
    public id: number,
    public image: string,
    public full_name: string,
    public email: string,
    public address: string,
    public country: string,
    public type: string,
    public mobile: []
  ) {}
}
export class AdditionalFeature {
  constructor(public id: number, public name: string, public value: string) {}
}

export class Location {
  constructor(public id: number, public lat: number, public lng: number) {}
}

export class Price {
  public sale: number;
  public rent: number;
}

export class Gallery {
  constructor(
    public id: number,
    public small: string,
    public medium: string,
    public big: string
  ) {}
}

export class Plan {
  constructor(
    public id: number,
    public name: string,
    public desc: string,
    public area: Area,
    public rooms: number,
    public baths: number,
    public image: string
  ) {}
}

export class Video {
  constructor(public id: number, public name: string, public link: string) {}
}

export class Pagination {
  constructor(
    public page: number,
    public perPage: number,
    public prePage: number,
    public nextPage: number,
    public total: number,
    public totalPages: number
  ) {}
}
