import { Title } from '@angular/platform-browser';
import { AppSettings } from 'src/app/app.settings';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-lang',
  templateUrl: './lang.component.html',
  styleUrls: ['./lang.component.scss'],
})
export class LangComponent implements OnInit {
  public flags = [
    { name: 'English', code: 'en', image: 'assets/images/flags/gb.svg' },
    { name: 'Arabic', code: 'ar', image: 'assets/images/flags/eg.svg' },
    // { name:'French', image: 'assets/images/flags/fr.svg' },
    // { name:'Russian', image: 'assets/images/flags/ru.svg' },
    // { name:'Turkish', image: 'assets/images/flags/tr.svg' }
  ];
  public flag: any;
  constructor(
    public translate: TranslateService,
    public appService: AppService,
    public titleService: Title,
    public appSettings: AppSettings
  ) {}

  ngOnInit() {
    this.flag = this.flags[0];
  }

  public changeLang(flag) {
    this.flag = flag;
    this.translate.use(flag.code).toPromise();
    this.appService.publishSomeData({
      language: flag.code,
    });
    if (flag.code === 'ar') {
      localStorage.setItem('boyot_langugae', 'ar');
      this.appSettings.settings.rtl = true;
      this.titleService.setTitle('بيووت');
    } else {
      localStorage.setItem('boyot_langugae', 'en');
      this.appSettings.settings.rtl = false;
      this.titleService.setTitle('Boyot');
    }
    window.location.reload();
  }
}
