// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: 'AIzaSyAhBJT6rsJuIca4IeZ_SJ1Yci6MX-10S-0',
    authDomain: 'boyotapp-64945.firebaseapp.com',
    databaseURL: 'https://boyotapp-64945.firebaseio.com',
    projectId: 'boyotapp-64945',
    storageBucket: 'boyotapp-64945.appspot.com',
    messagingSenderId: '1097696348275',
    appId: '1:1097696348275:web:35838ffb13e37cf39a4206',
    measurementId: 'G-WCWCKQ62FP',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
