import { AppService } from 'src/app/app.service';
import { Component, OnInit, Inject } from '@angular/core';
import { Settings, AppSettings } from './app.settings';
import { Router, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Meta, Title } from '@angular/platform-browser';
import { CanonicalService } from './shared/canonical.service';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public settings: Settings;
  isBrowser: boolean;

  constructor(
    public appSettings: AppSettings,
    public router: Router,
    public appService: AppService,
    private metaTagService: Meta,
    private titleService: Title,
    @Inject(PLATFORM_ID) platformId: Object,
    private canonicalService: CanonicalService,
    public translate: TranslateService
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    if (this.isBrowser) {
      //avoid server NETWORK error
      if (localStorage.getItem('boyot_user') !== null) {
        this.appSettings.loggedIn = true;
      }

      if (
        localStorage.getItem('boyot_langugae') !== null &&
        localStorage.getItem('boyot_langugae') === 'ar'
      ) {
        translate.addLangs(['en', 'ar']);
        translate.setDefaultLang('ar');
        localStorage.setItem('boyot_langugae', 'ar');
        this.settings = this.appSettings.settings;
        this.appSettings.settings.rtl = true;
        this.titleService.setTitle('بيووت | اجر , بيع واشتري عقارات في مصر .');
      } else {
        translate.addLangs(['en', 'ar']);
        translate.setDefaultLang('en');
        localStorage.setItem('boyot_langugae', 'en');
        this.settings = this.appSettings.settings;
        this.appSettings.settings.rtl = false;
        this.titleService.setTitle('Boyot | Buy , Rent and Compare homes in Egypt.');
      }

      this.appService.getFeatures().subscribe((res) => {
        localStorage.setItem(
          'property_amenties',
          JSON.stringify(res['message'])
        );
      });
    }
  }
  ngOnInit() {
    this.canonicalService.setCanonicalURL();
    this.metaTagService.addTags([
      {
        name: 'keywords',
        content:
          'Real estate,Properties, Boyot , Rent, Find Properties for sale, for rent or to Let , Sale , Real Estate company , buy property in Egypt ,rent property in Egypt,olx,aqarmap,aqar map,propertyfinder,aqaratmasr,semsarmasr,apartments for sale,apartments for rent,houses for sale,houses for rent,real estate egypt,property for sale,property for rent,Villas for sale,Villas for rent,chalets for rent,chalets for sale,studios for rent in cairo,Al rehab city apartments for sale,Madinaty villa for sale,Offices for rent,Offices for sale,شقق للبيع بالشيخ زايد,شقق للبيع بالعاصمة الادارية,شقق للبيع بمدينة نصر,شقق للبيع,شاليهات للأيجار بالساحل الشمالى,شاليهات للأيجار بالعين السخنة,شقق للبيع بالمعادى,شقق للأيجار بالمعادى,مكاتب للأيجار',
      },
      { name: 'robots', content: 'index, follow' },
      { name: 'author', content: 'Khaled Elrifaay' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'date', content: '2020-8-31', scheme: 'YYYY-MM-DD' },
      { charset: 'UTF-8' },
    ]);
  }
  ngAfterViewInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        setTimeout(() => {
          window.scrollTo(0, 0);
        });
      }
    });
  }
}
