import { Component, OnInit } from '@angular/core';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-our-agents',
  templateUrl: './our-agents.component.html',
  styleUrls: ['./our-agents.component.scss'],
})
export class OurAgentsComponent implements OnInit {
  public agents;
  public config: SwiperConfigInterface = {};
  constructor(public appService: AppService) {}

  ngOnInit() {
    this.getAgents();
  }
  getAgents() {
    this.appService.getAgents().subscribe((res) => {
      if (Array.isArray(res['message'])) {
        this.agents = res['message'];
      }
    });
  }
  ngAfterViewInit() {
    this.config = {
      observer: true,
      slidesPerView: 4,
      spaceBetween: 16,
      keyboard: true,
      navigation: true,
      pagination: false,
      grabCursor: true,
      loop: false,
      preloadImages: false,
      lazy: true,
      breakpoints: {
        600: {
          slidesPerView: 1,
        },
        960: {
          slidesPerView: 2,
        },
        1280: {
          slidesPerView: 3,
        },
      },
    };
  }
}
