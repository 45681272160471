import {
  SwiperDirective,
  SwiperPaginationInterface,
  SwiperConfigInterface,
} from 'ngx-swiper-wrapper';
import { Settings, AppSettings } from './../../app.settings';
import { AppService } from './../../app.service';
import {
  Component,
  OnInit,
  NgZone,
  ViewChild,
  Input,
  ElementRef,
  SimpleChange,
} from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MapsAPILoader } from '@agm/core';
import { Property, Pagination } from '../../app.models';

import { Subscription } from 'rxjs';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Router } from '@angular/router';
@Component({
  selector: 'app-map-view',
  templateUrl: './map-view.component.html',
  styleUrls: ['./map-view.component.scss'],
})
export class MapViewComponent implements OnInit {
  public submitForm: FormGroup;
  public lat: number = 40.678178;
  public lng: number = -73.944158;
  public zoom: number = 16;
  public settings: Settings;
  public searchFields: any;
  public removedSearchField: string;
  @ViewChild('addressAutocomplete') addressAutocomplete: ElementRef;
  public properties: Property[];
  public pagination: Pagination = new Pagination(1, 4, null, 2, 0, 0);
  public message: string;
  watcher: Subscription;
  activeMediaQuery = '';
  public viewType: string = 'grid';
  public viewCol: number = 25;
  public count: number = 4;
  public sort: string;
  public all_properties: Property[];
  @Input() property: Property;
  @Input() viewColChanged: boolean = false;
  @Input() fullWidthPage: boolean = true;
  public column: number = 4;
  public imagePath: any;
  // public address:string;
  @ViewChild(SwiperDirective) directiveRef: SwiperDirective;
  public config: SwiperConfigInterface = {};
  private paginationS: SwiperPaginationInterface = {
    el: '.swiper-pagination',
    clickable: true,
  };
  constructor(
    public appService: AppService,
    private fb: FormBuilder,
    public appSettings: AppSettings,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    public router: Router,
    public mediaObserver: MediaObserver
  ) {
    this.imagePath = this.appService.image_path;
    this.settings = this.appSettings.settings;
    this.watcher = mediaObserver.media$.subscribe((change: MediaChange) => {
      // console.log(change)
      if (change.mqAlias == 'xs') {
        this.viewCol = 100;
      } else if (change.mqAlias == 'sm') {
        this.viewCol = 50;
      } else if (change.mqAlias == 'md') {
        this.viewCol = 33.3;
      } else {
        this.viewCol = 25;
      }
    });
  }
  onMouseOver(infoWindow, gm) {
    if (gm.lastOpen != null) {
      gm.lastOpen.close();
    }

    gm.lastOpen = infoWindow;

    infoWindow.open();
  }

  onMouseOut(infoWindow, $event: MouseEvent) {
    // infoWindow.close();
  }
  onClickInfoView(marker) {}
  ngOnInit(): void {
    this.appSettings.routerUrl = this.router.url;
    this.getProperties();
  }
  private setCurrentPosition() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.lat = position.coords.latitude;
        this.lng = position.coords.longitude;
        this.submitForm
          .get('address')
          ['controls'].lat.setValue(position.coords.latitude);
        this.submitForm
          .get('address')
          ['controls'].lng.setValue(position.coords.longitude);
      });
    }
  }

  public onMarkerClick(ev) {}
  public searchChanged(event) {
    event.valueChanges.subscribe(() => {
      // this.resetLoadMore();
      this.searchFields = event.value;
      setTimeout(() => {
        this.removedSearchField = null;
      });
      if (!this.settings.searchOnBtnClick) {
        this.properties.length = 0;
      }
    });
    event.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(() => {
        if (!this.settings.searchOnBtnClick) {
          this.getProperties();
        }
      });
  }
  public removeSearchField(field) {
    this.message = null;
    this.removedSearchField = field;
  }
  public searchClicked(event) {
    this.properties.length = 0;
    this.searchFilter(event);
  }
  public searchFilter(data: any) {
    this.appService.searchFilter(data).subscribe((res) => {
      if (Array.isArray(res['message'])) {
        this.map_properties = res['message'];
        // for (let index = 0; index < res['message'].length; index++) {
        //   const element = res['message'][index];
        //   if (element.show_on_map === '1') {
        //     this.map_properties.push(element);
        //   }
        // }
        this.lat = this.map_properties[0].lat;
        this.lng = this.map_properties[0].lng;
        this.all_properties = res['message'];
        if (this.properties && this.properties.length > 0) {
          this.settings.loadMore.page++;
          this.pagination.page = this.settings.loadMore.page;
        }
        let result = this.filterData(res['message']);
        if (result.data.length == 0) {
          this.properties.length = 0;
          this.pagination = new Pagination(1, this.count, null, 2, 0, 0);
          this.message = 'No Results Found';
          return false;
        }
        if (this.properties && this.properties.length > 0) {
          this.properties = this.properties.concat(result.data);
        } else {
          this.properties = result.data;
        }
        this.pagination = result.pagination;
        this.message = null;

        if (this.properties.length === this.pagination.total) {
          this.settings.loadMore.complete = true;
          this.settings.loadMore.result = this.properties.length;
        } else {
          this.settings.loadMore.complete = false;
        }
      } else {
        this.properties.length = 0;
        this.message = 'No Results Found';
      }
    });
  }
  ngDoCheck() {
    if (this.settings.loadMore.load) {
      this.settings.loadMore.load = false;
      this.getProperties();
    }
  }

  ngOnDestroy() {
    this.resetLoadMore();
    this.watcher.unsubscribe();
  }
  public startLoad() {
    this.count = this.count + 8;
    const x = this.all_properties.slice(0, this.count);
    this.properties = x;
    if (this.properties.length === this.all_properties.length) {
      this.settings.loadMore.complete = true;
    }
  }
  public map_properties = [];
  public getProperties() {
    this.appService.getFeaturedProperties().subscribe((data) => {
      this.all_properties = data['message'];
      this.map_properties = data['message'];
      this.lat = this.map_properties[0].lat;
      this.lng = this.map_properties[0].lng;
      if (this.properties && this.properties.length > 0) {
        this.settings.loadMore.page++;
        this.pagination.page = this.settings.loadMore.page;
      }
      let result = this.filterData(data['message']);
      if (result.data.length == 0) {
        this.properties.length = 0;
        this.pagination = new Pagination(1, this.count, null, 2, 0, 0);
        this.message = 'No Results Found';
        return false;
      }
      if (this.properties && this.properties.length > 0) {
        this.properties = this.properties.concat(result.data);
      } else {
        this.properties = result.data;
      }
      this.pagination = result.pagination;
      this.message = null;

      if (this.properties.length === this.pagination.total) {
        this.settings.loadMore.complete = true;
        this.settings.loadMore.result = this.properties.length;
      } else {
        this.settings.loadMore.complete = false;
      }
    });
  }

  public resetLoadMore() {
    this.settings.loadMore.complete = false;
    this.settings.loadMore.start = false;
    this.settings.loadMore.page = 1;
    this.pagination = new Pagination(
      1,
      this.count,
      null,
      null,
      this.pagination.total,
      this.pagination.totalPages
    );
  }
  public filterData(data) {
    return this.appService.filterData(
      data,
      null,
      this.sort,
      this.pagination.page,
      this.pagination.perPage
    );
  }
  public changeCount(count) {
    this.count = count;
    this.resetLoadMore();
    this.properties.length = 0;
    this.getProperties();
  }
  public changeSorting(sort) {
    this.sort = sort;
    this.resetLoadMore();
    this.properties.length = 0;
    this.getProperties();
  }
  public changeViewType(obj) {
    this.viewType = obj.viewType;
    this.viewCol = obj.viewCol;
  }
  parseInt(val: any) {
    return parseInt(val);
  }
  ngAfterViewInit() {
    this.initCarousel();
    // this.appService.getAddress(this.property.location.lat, this.property.location.lng).subscribe(data=>{
    //   console.log(data['results'][0]['formatted_address']);
    //   this.address = data['results'][0]['formatted_address'];
    // })
  }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    if (changes.viewColChanged) {
      this.getColumnCount(changes.viewColChanged.currentValue);
      if (!changes.viewColChanged.isFirstChange()) {
        if (this.property.images.length > 1) {
          this.directiveRef.update();
        }
      }
    }

    for (let propName in changes) {
      // let changedProp = changes[propName];
      // if (!changedProp.isFirstChange()) {
      //   if(this.property.gallery.length > 1){
      //     this.initCarousel();
      //     this.config.autoHeight = true;
      //     this.directiveRef.update();
      //   }
      // }
    }
  }
  public getCurrency(price: any) {
    const x = new Intl.NumberFormat().format(price);
    return x;
  }
  public getColumnCount(value) {
    if (value == 25) {
      this.column = 4;
    } else if (value == 33.3) {
      this.column = 3;
    } else if (value == 50) {
      this.column = 2;
    } else {
      this.column = 1;
    }
  }

  public getStatusBgColor(status) {
    switch (status) {
      case 'sale':
        return '#1E88E5';
      case 'rent':
        return '#df7e47';
      case 'Open House':
        return '#009688';
      case 'No Fees':
        return '#FFA000';
      case 'Hot Offer':
        return '#F44336';
      case 'Sold':
        return '#000';
      case 'mortgage':
        return '#67a5e9';
      case 'featured':
        return '#ffc60b';
      default:
        return '#01579B';
    }
  }

  public initCarousel() {
    this.config = {
      slidesPerView: 1,
      spaceBetween: 0,
      keyboard: false,
      navigation: true,
      pagination: this.paginationS,
      grabCursor: true,
      loop: true,
      preloadImages: false,
      lazy: true,
      nested: true,
      // autoplay: {
      //   delay: 5000,
      //   disableOnInteraction: false
      // },
      speed: 500,
      effect: 'slide',
    };
  }

  // public addToCompare() {
  //   this.appService.addToCompare(
  //     this.property,
  //     CompareOverviewComponent,
  //     this.settings.rtl ? 'rtl' : 'ltr'
  //   );
  // }

  public onCompare() {
    return this.appService.Data.compareList.filter(
      (item) => item.id == this.property.id
    )[0];
  }

  public addToFavorites() {
    this.appService.addToFavorites(
      this.property,
      this.settings.rtl ? 'rtl' : 'ltr'
    );
  }

  public onFavorites() {
    return this.appService.Data.favorites.filter(
      (item) => item.id == this.property.id
    )[0];
  }
  public navigate(id) {
    // this.router.navigate(['/properties', id]);
  }
}
