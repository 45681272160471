import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-popular-links',
  templateUrl: './popular-links.html',
  styleUrls: ['./popular-links.scss'],
})
export class PopularLinks implements OnInit {
  english_section: boolean = true;
  constructor(public appService: AppService) {
    const language = localStorage.getItem('boyot_langugae');
    if (language !== null) {
      if (language === 'ar') {
        this.english_section = false;
      } else {
        this.english_section = true;
      }
    } else {
      this.english_section = true;
    }
    this.appService.getObservable().subscribe((data) => {
      // console.log('data', data);
      if (data.language !== null) {
        if (data.language === 'ar') {
          this.english_section = false;
        } else {
          this.english_section = true;
        }
      } else {
        this.english_section = true;
      }
    });
  }

  ngOnInit() {}
}
