import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { AppSettings } from 'src/app/app.settings';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-toolbar1',
  templateUrl: './toolbar1.component.html',
})
export class Toolbar1Component implements OnInit {
  @Output() onMenuIconClick: EventEmitter<any> = new EventEmitter<any>();
  constructor(
    public appService: AppService,
    public appsettings: AppSettings,
    public router: Router,
    public translate: TranslateService,
    public snackBar: MatSnackBar
  ) {}

  ngOnInit() {}

  public sidenavToggle() {
    this.onMenuIconClick.emit();
  }
  checkBeforeSubmit() {
    if (this.appsettings.loggedIn) {
      this.router.navigate(['/submit-property']);
    } else {
      this.snackBar.open(
        this.translate.instant('please login to complete your submitting !'),
        '',
        {
          panelClass: 'success',
          verticalPosition: 'top',
          duration: 3000,
        }
      );
      this.router.navigate(['/login']);
    }
  }
}
