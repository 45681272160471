import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { AppSettings } from 'src/app/app.settings';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
})
export class UserMenuComponent implements OnInit {
  public user: any;
  public image: any;
  constructor(
    public appService: AppService,
    public appSettings: AppSettings,
    public router: Router
  ) {
    this.appService.getObservable().subscribe((data) => {
      if (data.user) {
        this.user = data.user;
        if (
          this.user.image === 'https://www.w3schools.com/howto/img_avatar.png'
        ) {
          this.image = null;
        } else if (this.user.image == null) {
          this.image = null;
        } else if (this.user.image === 'NULL') {
          this.image = null;
        } else {
          this.image = this.appService.image_path + this.user.image;
        }
      }
    });
  }

  ngOnInit() {
    if (this.appSettings.loggedIn) {
      this.user =
        this.appSettings.user || JSON.parse(localStorage.getItem('boyot_user'));
      if (
        this.user.image === 'https://www.w3schools.com/howto/img_avatar.png'
      ) {
        this.image = null;
      } else if (this.user.image == null) {
        this.image = null;
      } else if (this.user.image === 'NULL') {
        this.image = null;
      } else {
        this.image = this.appService.image_path + this.user.image;
      }
    }
  }
  public signOut() {
    localStorage.clear();
    this.router.navigateByUrl('/login');
  }
}
