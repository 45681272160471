import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Property, Location } from './app.models';
import { AppSettings } from './app.settings';

export class Data {
  constructor(
    public properties: Property[],
    public compareList: Property[],
    public favorites: Property[],
    public locations: Location[]
  ) {}
}

@Injectable({
  providedIn: 'root',
})
export class AppService {
  public Data = new Data(
    [], // properties
    [], // compareList
    [], // favorites
    [] // locations
  );
  private fooSubject = new Subject<any>();

  public url = 'assets/data/';
  public dev_url = 'https://b-list-dev.boyot.app/';
  public apiKey = 'AIzaSyAvnrJLNZgTAbGcYfsAa-ktef9eJgtNp1U';
  public image_path = 'https://b-list-dev.boyot.app/images/';
  constructor(
    public http: HttpClient,
    private bottomSheet: MatBottomSheet,
    private snackBar: MatSnackBar,
    public appSettings: AppSettings
  ) {}

  public getProperties(slug?: any): Observable<Property[]> {
    const data = {
      type: 'all',
      country_id: 'Egypt',
    };
    return this.http.post<Property[]>(this.dev_url + 'properties', data);
  }
  public getPropertiesMock(slug?: any): Observable<Property[]> {
    return this.http.get<Property[]>(this.url + 'properties.json');
  }
  public getPropertyById(id): Observable<Property> {
    const data = {
      property_id: id,
    };
    return this.http.post<Property>(this.dev_url + 'getPropertyDetails', data);
  }

  public getFeaturedProperties(): Observable<Property[]> {
    return this.http.post<Property[]>(this.dev_url + 'featureProperties', '');
  }
  public searchFilter(data: any) {
    return this.http.post<Property[]>(this.dev_url + 'searchmobile', data);
  }
  public getRelatedProperties(propertyID): Observable<Property[]> {
    const data = {
      id: propertyID,
    };
    return this.http.post<Property[]>(this.dev_url + 'similer', data);
  }
  public getCities() {
    return this.http.get(this.url + 'csvjson.json');
  }
  public getPropertiesCount() {
    return this.http.get(this.dev_url + 'property_count');
  }
  public submitProperty(property): Observable<Property[]> {
    return this.http.post<Property[]>(
      this.dev_url + 'createproperty',
      property
    );
  }

  public getPropertiesByAgentId(agentId): Observable<Property[]> {
    const data = {
      user_id: agentId,
    };
    return this.http.post<Property[]>(this.dev_url + 'myproperties', data);
  }

  public getLocations(): Observable<Location[]> {
    return this.http.get<Location[]>(this.url + 'locations.json');
  }

  public getAddress(lat = 40.714224, lng = -73.961452) {
    return this.http.get(
      'https://maps.googleapis.com/maps/api/geocode/json?latlng=' +
        lat +
        ',' +
        lng +
        '&key=' +
        this.apiKey
    );
  }

  public getLatLng(address) {
    return this.http.get(
      'https://maps.googleapis.com/maps/api/geocode/json?key=' +
        this.apiKey +
        '&address=' +
        address
    );
  }

  public getFullAddress(lat = 40.714224, lng = -73.961452) {
    return this.http
      .get(
        'https://maps.googleapis.com/maps/api/geocode/json?latlng=' +
          lat +
          ',' +
          lng +
          '&key=' +
          this.apiKey
      )
      .subscribe((data) => {
        return data['results'][0]['formatted_address'];
      });
  }

  public addToCompare(property: Property, component, direction) {
    if (!this.Data.compareList.filter((item) => item.id == property.id)[0]) {
      this.Data.compareList.push(property);
      this.bottomSheet
        .open(component, {
          direction: direction,
        })
        .afterDismissed()
        .subscribe((isRedirect) => {
          if (isRedirect) {
            window.scrollTo(0, 0);
          }
        });
    }
  }

  public addToFavorites(property: Property, direction) {
    if (!this.Data.favorites.filter((item) => item.id == property.id)[0]) {
      this.Data.favorites.push(property);
      this.snackBar.open(
        'The property "' + property.title + '" has been added to favorites.',
        '×',
        {
          verticalPosition: 'top',
          duration: 3000,
          direction: direction,
        }
      );
    }
  }

  public getPropertyTypes() {
    return this.http.post(this.dev_url + 'getpropertytype', '');
  }
  public getPropertyFurnishings() {
    return this.http.post(this.dev_url + 'getfurnishings', '');
  }
  public getPropertyStatuses() {
    return [
      { id: 1, name: 'All' },
      { id: 1, name: 'Sale' },
      { id: 2, name: 'Rent' },
    ];
  }
  public getPayments() {
    return [
      { id: 1, name: 'Cash' },
      { id: 2, name: 'Installment' },
      { id: 3, name: 'Cash and Instalment' },
    ];
  }
  public getMockMobileNumbers() {
    return [
      { id: 1, name: '010914500010' },
      { id: 2, name: '010914800560' },
      { id: 3, name: '012224411202' },
    ];
  }
  public getFeaturesProperties() {
    return [
      {
        id: '2',
        created_at: '2020-04-21 09:25:00',
        updated_at: '2020-04-21 09:25:00',
        name: 'Club House',
        value: 'Club House',
      },
      {
        id: '3',
        created_at: '2020-04-21 09:25:23',
        updated_at: '2020-04-21 09:25:23',
        name: 'Supermarket',
        value: 'Supermarket',
      },
      {
        id: '4',
        created_at: '2020-04-21 09:25:52',
        updated_at: '2020-04-21 09:25:52',
        name: 'Furnished',
        value: 'Furnished',
      },
      {
        id: '5',
        created_at: '2020-04-21 09:26:21',
        updated_at: '2020-04-21 09:26:21',
        name: 'Nursery',
        value: 'Nursery',
      },
      {
        id: '6',
        created_at: '2020-04-21 09:26:35',
        updated_at: '2020-04-21 09:26:35',
        name: 'Masged',
        value: 'Masged',
      },
      {
        id: '7',
        created_at: '2020-04-21 09:27:00',
        updated_at: '2020-04-21 09:27:00',
        name: 'Kids Cinema',
        value: 'Kids Cinema',
      },
      {
        id: '8',
        created_at: '2020-04-21 09:27:31',
        updated_at: '2020-04-21 09:27:31',
        name: 'Kids Area',
        value: 'Kids Area',
      },
      {
        id: '9',
        created_at: '2020-04-21 09:27:47',
        updated_at: '2020-04-21 09:27:47',
        name: 'Barbecue Area',
        value: 'Barbecue Area',
      },
      {
        id: '10',
        created_at: '2020-04-21 09:28:01',
        updated_at: '2020-04-21 09:28:01',
        name: 'Smart House',
        value: 'Smart House',
      },
      {
        id: '11',
        created_at: '2020-04-21 09:28:25',
        updated_at: '2020-04-21 09:28:25',
        name: 'Semi Smart Home',
        value: 'Semi Smart Home',
      },
      {
        id: '12',
        created_at: '2020-04-21 09:28:43',
        updated_at: '2020-04-21 09:28:43',
        name: 'Satellite/Cable TV',
        value: 'Satellite/Cable TV',
      },
      {
        id: '13',
        created_at: '2020-04-21 09:29:00',
        updated_at: '2020-04-21 09:29:00',
        name: 'Card Entrance',
        value: 'Card Entrance',
      },
      {
        id: '14',
        created_at: '2020-04-21 09:29:13',
        updated_at: '2020-04-21 09:29:13',
        name: 'Floor Heating System',
        value: 'Floor Heating System',
      },
      {
        id: '15',
        created_at: '2020-04-21 09:29:26',
        updated_at: '2020-04-21 09:29:26',
        name: 'Wifi',
        value: 'Wifi',
      },
      {
        id: '16',
        created_at: '2020-04-21 09:29:36',
        updated_at: '2020-04-21 09:29:36',
        name: 'Security',
        value: 'Security',
      },
      {
        id: '17',
        created_at: '2020-04-21 09:29:51',
        updated_at: '2020-04-21 09:29:51',
        name: 'Concierge',
        value: 'Concierge',
      },
      {
        id: '18',
        created_at: '2020-04-21 09:30:05',
        updated_at: '2020-04-21 09:30:05',
        name: 'Lobby In Building',
        value: 'Lobby In Building',
      },
      {
        id: '19',
        created_at: '2020-04-21 09:30:37',
        updated_at: '2020-04-21 09:30:37',
        name: 'Camera System',
        value: 'Camera System',
      },
      {
        id: '20',
        created_at: '2020-04-21 09:30:55',
        updated_at: '2020-04-21 09:30:55',
        name: 'Video Security Intercom',
        value: 'Video Security Intercom',
      },
      {
        id: '21',
        created_at: '2020-04-21 09:31:19',
        updated_at: '2020-04-21 09:31:19',
        name: 'Waiting Area',
        value: 'Waiting Area',
      },
      {
        id: '22',
        created_at: '2020-04-21 09:31:35',
        updated_at: '2020-04-21 09:31:35',
        name: 'Maintenance',
        value: 'Maintenance',
      },
      {
        id: '23',
        created_at: '2020-04-21 09:31:50',
        updated_at: '2020-04-21 09:31:50',
        name: 'Trash Pump',
        value: 'Trash Pump',
      },
      {
        id: '24',
        created_at: '2020-04-21 09:32:02',
        updated_at: '2020-04-21 09:32:02',
        name: 'Balcony',
        value: 'Balcony',
      },
      {
        id: '25',
        created_at: '2020-04-21 09:32:24',
        updated_at: '2020-04-21 09:32:24',
        name: 'Landscapes',
        value: 'Landscapes',
      },
      {
        id: '26',
        created_at: '2020-04-21 09:32:38',
        updated_at: '2020-04-21 09:32:38',
        name: 'Private landscape',
        value: 'Private landscape',
      },
      {
        id: '27',
        created_at: '2020-04-21 09:32:51',
        updated_at: '2020-04-21 09:32:51',
        name: 'Solar Energy',
        value: 'Solar Energy',
      },
      {
        id: '28',
        created_at: '2020-04-21 09:33:07',
        updated_at: '2020-04-21 09:33:07',
        name: 'Pets Allowed',
        value: 'Pets Allowed',
      },
      {
        id: '29',
        created_at: '2020-04-21 09:33:46',
        updated_at: '2020-04-21 09:33:46',
        name: 'Covered Parking',
        value: 'Covered Parking',
      },
      {
        id: '30',
        created_at: '2020-04-21 09:36:11',
        updated_at: '2020-04-21 09:36:11',
        name: 'Built In Wardrobes',
        value: 'Built In Wardrobes',
      },
      {
        id: '31',
        created_at: '2020-04-21 09:36:21',
        updated_at: '2020-04-21 09:36:21',
        name: 'Kitchen Appliances',
        value: 'Kitchen Appliances',
      },
      {
        id: '32',
        created_at: '2020-04-21 09:36:42',
        updated_at: '2020-04-21 09:36:42',
        name: 'Central A/C',
        value: 'Central A/C',
      },
      {
        id: '33',
        created_at: '2020-04-21 09:36:57',
        updated_at: '2020-04-21 09:36:57',
        name: 'Clinics',
        value: 'Clinics',
      },
      {
        id: '34',
        created_at: '2020-04-21 09:37:13',
        updated_at: '2020-04-21 09:37:13',
        name: 'Spa',
        value: 'Spa',
      },
      {
        id: '35',
        created_at: '2020-04-21 09:37:30',
        updated_at: '2020-04-21 09:37:30',
        name: 'Turkish Bath',
        value: 'Turkish Bath',
      },
      {
        id: '36',
        created_at: '2020-04-21 09:37:48',
        updated_at: '2020-04-21 09:37:48',
        name: 'Fitness Area',
        value: 'Fitness Area',
      },
      {
        id: '37',
        created_at: '2020-04-21 09:38:06',
        updated_at: '2020-04-21 09:38:06',
        name: 'Billiard Room',
        value: 'Billiard Room',
      },
      {
        id: '38',
        created_at: '2020-04-21 09:38:20',
        updated_at: '2020-04-21 09:38:20',
        name: 'Private Pool',
        value: 'Private Pool',
      },
      {
        id: '39',
        created_at: '2020-04-21 09:38:30',
        updated_at: '2020-04-21 09:38:30',
        name: "Children's Pool",
        value: "Children's Pool",
      },
      {
        id: '40',
        created_at: '2020-04-21 09:39:02',
        updated_at: '2020-04-21 09:39:02',
        name: 'Women`s Swimming Pool',
        value: 'Women`s Swimming Pool',
      },
    ];
  }
  public getRentalFrequency() {
    return [
      { id: 1, name: 'Yearly' },
      { id: 2, name: 'Monthly' },
      { id: 3, name: 'Weekly' },
      { id: 3, name: 'Daily' },
    ];
  }
  public getPropertiesViews() {
    const data = {
      country_id: 1,
    };
    return this.http.post(this.dev_url + 'getpropertyview', data);
  }
  public getCurrencies() {
    return [
      { id: 1, name: 'EGP' },
      { id: 2, name: 'KWD' },
      // { id: 3, name: 'TR' },
      { id: 4, name: 'USD' },
    ];
  }
  public getNeighborhoods() {
    return [
      { id: 1, name: 'Astoria', cityId: 1 },
      { id: 2, name: 'Midtown', cityId: 1 },
      { id: 3, name: 'Chinatown', cityId: 1 },
      { id: 4, name: 'Austin', cityId: 2 },
      { id: 5, name: 'Englewood', cityId: 2 },
      { id: 6, name: 'Riverdale', cityId: 2 },
      { id: 7, name: 'Hollywood', cityId: 3 },
      { id: 8, name: 'Sherman Oaks', cityId: 3 },
      { id: 9, name: 'Highland Park', cityId: 3 },
      { id: 10, name: 'Belltown', cityId: 4 },
      { id: 11, name: 'Queen Anne', cityId: 4 },
      { id: 12, name: 'Green Lake', cityId: 4 },
    ];
  }

  public getStreets() {
    return [
      { id: 1, name: 'Astoria Street #1', cityId: 1, neighborhoodId: 1 },
      { id: 2, name: 'Astoria Street #2', cityId: 1, neighborhoodId: 1 },
      { id: 3, name: 'Midtown Street #1', cityId: 1, neighborhoodId: 2 },
      { id: 4, name: 'Midtown Street #2', cityId: 1, neighborhoodId: 2 },
      { id: 5, name: 'Chinatown Street #1', cityId: 1, neighborhoodId: 3 },
      { id: 6, name: 'Chinatown Street #2', cityId: 1, neighborhoodId: 3 },
      { id: 7, name: 'Austin Street #1', cityId: 2, neighborhoodId: 4 },
      { id: 8, name: 'Austin Street #2', cityId: 2, neighborhoodId: 4 },
      { id: 9, name: 'Englewood Street #1', cityId: 2, neighborhoodId: 5 },
      { id: 10, name: 'Englewood Street #2', cityId: 2, neighborhoodId: 5 },
      { id: 11, name: 'Riverdale Street #1', cityId: 2, neighborhoodId: 6 },
      { id: 12, name: 'Riverdale Street #2', cityId: 2, neighborhoodId: 6 },
      { id: 13, name: 'Hollywood Street #1', cityId: 3, neighborhoodId: 7 },
      { id: 14, name: 'Hollywood Street #2', cityId: 3, neighborhoodId: 7 },
      { id: 15, name: 'Sherman Oaks Street #1', cityId: 3, neighborhoodId: 8 },
      { id: 16, name: 'Sherman Oaks Street #2', cityId: 3, neighborhoodId: 8 },
      { id: 17, name: 'Highland Park Street #1', cityId: 3, neighborhoodId: 9 },
      { id: 18, name: 'Highland Park Street #2', cityId: 3, neighborhoodId: 9 },
      { id: 19, name: 'Belltown Street #1', cityId: 4, neighborhoodId: 10 },
      { id: 20, name: 'Belltown Street #2', cityId: 4, neighborhoodId: 10 },
      { id: 21, name: 'Queen Anne Street #1', cityId: 4, neighborhoodId: 11 },
      { id: 22, name: 'Queen Anne Street #2', cityId: 4, neighborhoodId: 11 },
      { id: 23, name: 'Green Lake Street #1', cityId: 4, neighborhoodId: 12 },
      { id: 24, name: 'Green Lake Street #2', cityId: 4, neighborhoodId: 12 },
    ];
  }

  public getFeatures() {
    return this.http.post(this.dev_url + 'getamenities', '');
  }

  public getHomeCarouselSlides() {
    // return this.http.get<any[]>(this.url + 'slides.json');
    const data = {
      type: 'all',
      country_id: 'Egypt',
    };
    return this.http.post<Property[]>(this.dev_url + 'properties', data);
  }
  public filterDataMock(data, params: any, sort?, page?, perPage?) {
    if (params) {
      if (params.propertyType) {
        data = data.filter(
          (property) => property.propertyType == params.propertyType.name
        );
      }

      if (params.propertyStatus && params.propertyStatus.length) {
        let statuses = [];
        params.propertyStatus.forEach((status) => {
          statuses.push(status.name);
        });
        let properties = [];
        data.filter((property) =>
          property.propertyStatus.forEach((status) => {
            if (statuses.indexOf(status) > -1) {
              if (!properties.includes(property)) {
                properties.push(property);
              }
            }
          })
        );
        data = properties;
      }

      if (params.price) {
        if (this.appSettings.settings.currency == 'USD') {
          if (params.price.from) {
            data = data.filter((property) => {
              if (
                property.priceDollar.sale &&
                property.priceDollar.sale >= params.price.from
              ) {
                return true;
              }
              if (
                property.priceDollar.rent &&
                property.priceDollar.rent >= params.price.from
              ) {
                return true;
              }
              return false;
            });
          }
          if (params.price.to) {
            data = data.filter((property) => {
              if (
                property.priceDollar.sale &&
                property.priceDollar.sale <= params.price.to
              ) {
                return true;
              }
              if (
                property.priceDollar.rent &&
                property.priceDollar.rent <= params.price.to
              ) {
                return true;
              }
              return false;
            });
          }
        }
        if (this.appSettings.settings.currency == 'EUR') {
          if (params.price.from) {
            data = data.filter((property) => {
              if (
                property.priceEuro.sale &&
                property.priceEuro.sale >= params.price.from
              ) {
                return true;
              }
              if (
                property.priceEuro.rent &&
                property.priceEuro.rent >= params.price.from
              ) {
                return true;
              }
              return false;
            });
          }
          if (params.price.to) {
            data = data.filter((property) => {
              if (
                property.priceEuro.sale &&
                property.priceEuro.sale <= params.price.to
              ) {
                return true;
              }
              if (
                property.priceEuro.rent &&
                property.priceEuro.rent <= params.price.to
              ) {
                return true;
              }
              return false;
            });
          }
        }
      }

      if (params.city) {
        data = data.filter((property) => property.city == params.city.name);
      }

      if (params.zipCode) {
        data = data.filter((property) => property.zipCode == params.zipCode);
      }

      if (params.neighborhood && params.neighborhood.length) {
        let neighborhoods = [];
        params.neighborhood.forEach((item) => {
          neighborhoods.push(item.name);
        });
        let properties = [];
        data.filter((property) =>
          property.neighborhood.forEach((item) => {
            if (neighborhoods.indexOf(item) > -1) {
              if (!properties.includes(property)) {
                properties.push(property);
              }
            }
          })
        );
        data = properties;
      }

      if (params.street && params.street.length) {
        let streets = [];
        params.street.forEach((item) => {
          streets.push(item.name);
        });
        let properties = [];
        data.filter((property) =>
          property.street.forEach((item) => {
            if (streets.indexOf(item) > -1) {
              if (!properties.includes(property)) {
                properties.push(property);
              }
            }
          })
        );
        data = properties;
      }

      if (params.bedrooms) {
        if (params.bedrooms.from) {
          data = data.filter(
            (property) => property.bedrooms >= params.bedrooms.from
          );
        }
        if (params.bedrooms.to) {
          data = data.filter(
            (property) => property.bedrooms <= params.bedrooms.to
          );
        }
      }

      if (params.bathrooms) {
        if (params.bathrooms.from) {
          data = data.filter(
            (property) => property.bathrooms >= params.bathrooms.from
          );
        }
        if (params.bathrooms.to) {
          data = data.filter(
            (property) => property.bathrooms <= params.bathrooms.to
          );
        }
      }

      if (params.garages) {
        if (params.garages.from) {
          data = data.filter(
            (property) => property.garages >= params.garages.from
          );
        }
        if (params.garages.to) {
          data = data.filter(
            (property) => property.garages <= params.garages.to
          );
        }
      }

      if (params.area) {
        if (params.area.from) {
          data = data.filter(
            (property) => property.area.value >= params.area.from
          );
        }
        if (params.area.to) {
          data = data.filter(
            (property) => property.area.value <= params.area.to
          );
        }
      }

      if (params.yearBuilt) {
        if (params.yearBuilt.from) {
          data = data.filter(
            (property) => property.yearBuilt >= params.yearBuilt.from
          );
        }
        if (params.yearBuilt.to) {
          data = data.filter(
            (property) => property.yearBuilt <= params.yearBuilt.to
          );
        }
      }

      if (params.features) {
        let arr = [];
        params.features.forEach((feature) => {
          if (feature.selected) arr.push(feature.name);
        });
        if (arr.length > 0) {
          let properties = [];
          data.filter((property) =>
            property.features.forEach((feature) => {
              if (arr.indexOf(feature) > -1) {
                if (!properties.includes(property)) {
                  properties.push(property);
                }
              }
            })
          );
          data = properties;
        }
      }
    }

    // console.log(data)

    //for show more properties mock data
    for (var index = 0; index < 2; index++) {
      data = data.concat(data);
    }

    this.sortData(sort, data);
    return this.paginator(data, page, perPage);
  }
  public filterData(data, params: any, sort?, page?, perPage?) {
    if (params) {
      if (params.property_type.id) {
        data = data.filter(
          (property) => property.property_type.id == params.property_type.id
        );
      }

      // if (params.purpose) {
      //   let statuses = [];
      //   params.propertyStatus.forEach((status) => {
      //     statuses.push(status.name);
      //   });
      //   let properties = [];
      //   data.filter((property) =>
      //     property.propertyStatus.forEach((status) => {
      //       if (statuses.indexOf(status) > -1) {
      //         if (!properties.includes(property)) {
      //           properties.push(property);
      //         }
      //       }
      //     })
      //   );
      //   data = properties;
      // }

      if (params.price) {
        if (params.price.lower) {
          data = data.filter((property) => {
            if (property.price >= params.price.lower) {
              return true;
            }
            return false;
          });
        }
        if (params.price.upper) {
          data = data.filter((property) => {
            if (property.price <= params.price.upper) {
              return true;
            }
            return false;
          });
        }
      }

      // if (params.city) {
      //   data = data.filter((property) => property.city == params.city.name);
      // }

      // if (params.zipCode) {
      //   data = data.filter((property) => property.zipCode == params.zipCode);
      // }

      // if (params.neighborhood && params.neighborhood.length) {
      //   let neighborhoods = [];
      //   params.neighborhood.forEach((item) => {
      //     neighborhoods.push(item.name);
      //   });
      //   let properties = [];
      //   data.filter((property) =>
      //     property.neighborhood.forEach((item) => {
      //       if (neighborhoods.indexOf(item) > -1) {
      //         if (!properties.includes(property)) {
      //           properties.push(property);
      //         }
      //       }
      //     })
      //   );
      //   data = properties;
      // }

      // if (params.street && params.street.length) {
      //   let streets = [];
      //   params.street.forEach((item) => {
      //     streets.push(item.name);
      //   });
      //   let properties = [];
      //   data.filter((property) =>
      //     property.street.forEach((item) => {
      //       if (streets.indexOf(item) > -1) {
      //         if (!properties.includes(property)) {
      //           properties.push(property);
      //         }
      //       }
      //     })
      //   );
      //   data = properties;
      // }

      if (params.bedrooms) {
        if (params.bedrooms) {
          data = data.filter(
            (property) => property.bedrooms >= params.bedrooms
          );
        }
      }

      if (params.bathrooms) {
        if (params.bathrooms) {
          data = data.filter(
            (property) => property.bathrooms >= params.bathrooms
          );
        }
      }
      if (params.size) {
        if (params.size.lower) {
          data = data.filter(
            (property) => property.size.lower >= params.size.lower
          );
        }
        if (params.size.upper) {
          data = data.filter(
            (property) => property.size.upper <= params.size.upper
          );
        }
      }
    }
    //for show more properties mock data
    // for (var index = 0; index < 2; index++) {
    //   data = data.concat(data);
    // }

    this.sortData(sort, data);
    return this.paginator(data, page, perPage);
  }
  public filterDataForHome(data, params: any, sort?, page?, perPage?) {
    this.sortData('Newest', data);
    return this.paginator(data, page, perPage);
  }
  public sortData(sort, data) {
    if (sort) {
      switch (sort) {
        case 'Newest':
          data = data.sort((a, b) => {
            return <any>new Date(b.create_date) - <any>new Date(a.create_date);
          });
          break;
        case 'Oldest':
          data = data.sort((a, b) => {
            return <any>new Date(a.create_date) - <any>new Date(b.create_date);
          });
          break;
        case 'Popular':
          data = data.sort((a, b) => {
            if (
              a.ratingsValue / a.ratingsCount <
              b.ratingsValue / b.ratingsCount
            ) {
              return 1;
            }
            if (
              a.ratingsValue / a.ratingsCount >
              b.ratingsValue / b.ratingsCount
            ) {
              return -1;
            }
            return 0;
          });
          break;
        case 'Price (Low to High)':
          if (this.appSettings.settings.currency == 'USD') {
            data = data.sort(
              (a, b) => parseFloat(a.price) - parseFloat(b.price)
            );
          }
          if (this.appSettings.settings.currency == 'EGP') {
            data = data.sort(
              (a, b) => parseFloat(a.price) - parseFloat(b.price)
            );
          }
          break;
        case 'Price (High to Low)':
          if (this.appSettings.settings.currency == 'USD') {
            data = data.sort(
              (a, b) => parseFloat(b.price) - parseFloat(a.price)
            );
          }
          if (this.appSettings.settings.currency == 'EGP') {
            data = data.sort(
              (a, b) => parseFloat(b.price) - parseFloat(a.price)
            );
          }
          break;
        default:
          break;
      }
    }
    return data;
  }

  public paginator(items, page?, perPage?) {
    var page = page || 1,
      perPage = perPage || 4,
      offset = (page - 1) * perPage,
      paginatedItems = items.slice(offset).slice(0, perPage),
      totalPages = Math.ceil(items.length / perPage);
    return {
      data: paginatedItems,
      pagination: {
        page: page,
        perPage: perPage,
        prePage: page - 1 ? page - 1 : null,
        nextPage: totalPages > page ? page + 1 : null,
        total: items.length,
        totalPages: totalPages,
      },
    };
  }

  public getTestimonials() {
    return [
      {
        text:
          'Donec molestie turpis ut mollis efficitur. Nam fringilla libero vel dictum vulputate. In malesuada, ligula non ornare consequat, augue nibh luctus nisl, et lobortis justo ipsum nec velit. Praesent lacinia quam ut nulla gravida, at viverra libero euismod. Sed tincidunt tempus augue vitae malesuada. Vestibulum eu lectus nisi. Aliquam erat volutpat.',
        author: 'Mr. Adam Sandler',
        position: 'General Director',
        image: 'assets/images/profile/adam.jpg',
      },
      {
        text:
          'Donec molestie turpis ut mollis efficitur. Nam fringilla libero vel dictum vulputate. In malesuada, ligula non ornare consequat, augue nibh luctus nisl, et lobortis justo ipsum nec velit. Praesent lacinia quam ut nulla gravida, at viverra libero euismod. Sed tincidunt tempus augue vitae malesuada. Vestibulum eu lectus nisi. Aliquam erat volutpat.',
        author: 'Ashley Ahlberg',
        position: 'Housewife',
        image: 'assets/images/profile/ashley.jpg',
      },
      {
        text:
          'Donec molestie turpis ut mollis efficitur. Nam fringilla libero vel dictum vulputate. In malesuada, ligula non ornare consequat, augue nibh luctus nisl, et lobortis justo ipsum nec velit. Praesent lacinia quam ut nulla gravida, at viverra libero euismod. Sed tincidunt tempus augue vitae malesuada. Vestibulum eu lectus nisi. Aliquam erat volutpat.',
        author: 'Bruno Vespa',
        position: 'Blogger',
        image: 'assets/images/profile/bruno.jpg',
      },
      {
        text:
          'Donec molestie turpis ut mollis efficitur. Nam fringilla libero vel dictum vulputate. In malesuada, ligula non ornare consequat, augue nibh luctus nisl, et lobortis justo ipsum nec velit. Praesent lacinia quam ut nulla gravida, at viverra libero euismod. Sed tincidunt tempus augue vitae malesuada. Vestibulum eu lectus nisi. Aliquam erat volutpat.',
        author: 'Mrs. Julia Aniston',
        position: 'Marketing Manager',
        image: 'assets/images/profile/julia.jpg',
      },
    ];
  }

  public getAgents() {
    const data = {
      country_id: 'Egypt',
    };
    return this.http.post(this.dev_url + 'brockers', data);
  }
  public getAgent(agentId) {
    const data = {
      id: agentId,
    };
    return this.http.post(this.dev_url + 'getBrocker', data);
  }
  public getClients() {
    return [
      { name: 'aloha', image: 'assets/images/clients/aloha.png' },
      { name: 'dream', image: 'assets/images/clients/dream.png' },
      { name: 'congrats', image: 'assets/images/clients/congrats.png' },
      { name: 'best', image: 'assets/images/clients/best.png' },
      { name: 'original', image: 'assets/images/clients/original.png' },
      { name: 'retro', image: 'assets/images/clients/retro.png' },
      { name: 'king', image: 'assets/images/clients/king.png' },
      { name: 'love', image: 'assets/images/clients/love.png' },
      { name: 'the', image: 'assets/images/clients/the.png' },
      { name: 'easter', image: 'assets/images/clients/easter.png' },
      { name: 'with', image: 'assets/images/clients/with.png' },
      { name: 'special', image: 'assets/images/clients/special.png' },
      { name: 'bravo', image: 'assets/images/clients/bravo.png' },
    ];
  }
  public POST(slug: any, user: any): Observable<any> {
    return this.http.post(this.dev_url + slug, user);
  }
  public getCountryCodePrefix() {
    const items = [
      {
        name: '+2 Egypt',
        dial_code: '+2',
        code: '1',
        country: 'Egypt',
      },
      {
        name: '+965 Kuwait',
        dial_code: '+965',
        code: '2',
        country: 'Kuwait',
      },
      {
        name: '+966 Saudi Arabia',
        dial_code: '+966',
        code: '3',
        country: 'Saudi Arabia',
      },
      {
        name: '+974 Qatar',
        dial_code: '+974',
        code: '4',
        country: 'Qatar',
      },
      {
        name: '+962 Jordan',
        dial_code: '+962',
        code: '5',
        country: 'Jordan',
      },
      {
        name: '+90 Turkey',
        dial_code: '+90',
        code: '6',
        country: 'Turkey',
      },
      {
        name: '+212 Morocco',
        dial_code: '+212',
        code: '7',
        country: 'Morocco',
      },
      {
        name: '+968 Oman',
        dial_code: '+968',
        code: '8',
        country: 'Oman',
      },
      {
        name: '+249 Sudan',
        dial_code: '+249',
        code: '9',
        country: 'Sudan',
      },
      {
        name: '+216 Tunisia',
        dial_code: '+216',
        code: '10',
        country: 'Tunisia',
      },

      {
        name: '+971 United Arab Emirates',
        dial_code: '+971',
        code: '11',
        country: 'United Arab Emirates',
      },

      {
        name: '+967 Yemen',
        dial_code: '+967',
        code: '12',
        country: 'Yemen',
      },
      {
        name: '+1 United States',
        dial_code: '+1',
        code: '13',
        country: 'United States',
      },
    ];
    return items;
  }
  public publishSomeData(data: any) {
    this.fooSubject.next(data);
  }

  public getObservable(): Subject<any> {
    return this.fooSubject;
  }
}
