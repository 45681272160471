import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  ViewChild,
  NgZone,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AppService } from '../../app.service';
import { MapsAPILoader } from '@agm/core';

@Component({
  selector: 'app-properties-search',
  templateUrl: './properties-search.component.html',
  styleUrls: ['./properties-search.component.scss'],
})
export class PropertiesSearchComponent implements OnInit {
  @Input() variant: number = 1;
  @Input() vertical: boolean = false;
  @Input() searchOnBtnClick: boolean = false;
  @Input() removedSearchField: string;
  @Output() onSearchChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSearchClick: EventEmitter<any> = new EventEmitter<any>();
  // @ViewChild('addressAutocomplete') addressAutocomplete: ElementRef;
  public showMore: boolean = false;
  public form: FormGroup;
  public propertyTypes = [];
  public propertyStatuses = [];
  public cities = [];
  public neighborhoods = [];
  public streets = [];
  public features = [];
  public currentLanguage: any;
  public properties_count: any;
  constructor(
    public appService: AppService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    public fb: FormBuilder
  ) {
    this.getCities();
    this.getPropertiesCount();
    this.currentLanguage = localStorage.getItem('boyot_langugae');
  }
  color: string = 'primary';

  changeStyle($event) {
    console.log($event);

    this.color = $event.type == 'mouseover' ? 'warn' : 'primary';
  }
  getCities() {
    this.appService.getCities().subscribe((res) => {
      // console.log('res', res);
      this.items = res;
      this.itemsReal = res;
    });
  }
  getPropertiesCount() {
    this.appService.getPropertiesCount().subscribe((res) => {
      this.properties_count = res['count'];
    });
  }
  // public placesAutocomplete() {
  //   const options = {
  //     types: [],
  //     componentRestrictions: { country: 'eg' },
  //   };
  //   this.mapsAPILoader.load().then(() => {
  //     let autocomplete = new google.maps.places.Autocomplete(
  //       this.addressAutocomplete.nativeElement,
  //       options
  //     );
  //     autocomplete.addListener('place_changed', () => {
  //       this.ngZone.run(() => {
  //         let place: google.maps.places.PlaceResult = autocomplete.getPlace();
  //         if (place.geometry === undefined || place.geometry === null) {
  //           return;
  //         }
  //         this.form.get('lat').setValue(place.geometry.location.lat());
  //         this.form.get('lng').setValue(place.geometry.location.lng());
  //         this.getAddress();
  //       });
  //     });
  //   });
  // }
  isItemAvailable = false;
  items: any;
  itemsReal: any;
  initializeItems() {
    this.items = this.itemsReal;
  }
  selectDistrict(item) {
    // console.log('item', item);
    this.form.get('location').setValue(item.Name);
    this.form.get('districts').setValue(item.ID);
    this.isItemAvailable = false;
  }
  getItems(ev: any) {
    // console.log('ev', ev);
    this.initializeItems();
    const val = ev.target.value;
    if (val && val.trim() !== '') {
      this.isItemAvailable = true;
      this.items = this.items
        .filter((item) => {
          return (
            item.Governorate.toLowerCase().indexOf(val.toLowerCase()) > -1 ||
            item.Governorate_Ar.toLowerCase().indexOf(val.toLowerCase()) > -1 ||
            item.District.toLowerCase().indexOf(val.toLowerCase()) > -1 ||
            item.District_Ar.toLowerCase().indexOf(val.toLowerCase()) > -1 ||
            item.Name.toLowerCase().indexOf(val.toLowerCase()) > -1 ||
            item.Name_Ar.toLowerCase().indexOf(val.toLowerCase()) > -1 ||
            item.District2.toLowerCase().indexOf(val.toLowerCase()) > -1 ||
            item.District_Ar2.toLowerCase().indexOf(val.toLowerCase()) > -1 ||
            item.District3.toLowerCase().indexOf(val.toLowerCase()) > -1 ||
            item.District_Ar3.toLowerCase().indexOf(val.toLowerCase()) > -1
          );
        })
        .sort(this.compare);
    } else {
      this.isItemAvailable = false;
    }
  }
  compare(a, b) {
    if (a.ID > b.ID) return 1;
    if (b.ID > a.ID) return -1;

    return 0;
  }
  ngOnInit() {
    if (this.vertical) {
      this.showMore = true;
    }
    this.appService.getPropertyTypes().subscribe((res) => {
      this.propertyTypes = res['message'];
      this.propertyTypes.push({ id: '', name: 'All' });
    });
    this.propertyStatuses = this.appService.getPropertyStatuses();
    this.neighborhoods = this.appService.getNeighborhoods();
    this.streets = this.appService.getStreets();
    this.appService.getFeatures().subscribe((res) => {
      this.features = res['message'];
    });
    this.form = this.fb.group({
      country: 'Egypt',
      property_type: '',
      purpose: '1',
      price: this.fb.group({
        upper: null,
        lower: null,
      }),
      size: this.fb.group({
        upper: null,
        lower: null,
      }),
      city: null,
      districts: null,
      rooms: null,
      bathrooms: null,
      location: null,
      payment_method: null,
      lat: null,
      lng: null,
      offset: 1,
      limit: 100,
      keyword: null,
    });

    this.onSearchChange.emit(this.form);
    // this.placesAutocomplete();
  }
  public buildFeatures() {
    const arr = this.features.map((feature) => {
      return this.fb.group({
        id: feature.id,
        name: feature.name,
        selected: feature.selected,
      });
    });
    return this.fb.array(arr);
  }

  ngOnChanges() {
    if (this.removedSearchField) {
      if (this.removedSearchField.indexOf('.') > -1) {
        let arr = this.removedSearchField.split('.');
        this.form.controls[arr[0]]['controls'][arr[1]].reset();
      } else if (this.removedSearchField.indexOf(',') > -1) {
        let arr = this.removedSearchField.split(',');
        this.form.controls[arr[0]]['controls'][arr[1]]['controls'][
          'selected'
        ].setValue(false);
      } else {
        this.form.controls[this.removedSearchField].reset();
      }
    }
  }

  public reset() {
    this.form.reset({
      country: 'Egypt',
      property_type: null,
      purpose: '1',
      price: this.fb.group({
        upper: null,
        lower: null,
      }),
      size: this.fb.group({
        upper: null,
        lower: null,
      }),
      city: null,
      districts: null,
      rooms: null,
      bathrooms: null,
      payment_method: null,
      lat: null,
      lng: null,
      keyword: null,
    });
    this.form.value['lat'] = null;
    this.form.value['lng'] = null;
  }

  public search() {
    if (
      this.form.value['size'].upper == null ||
      this.form.value['size'].lower == null
    ) {
      this.form.value['size'].upper = 10000000;
      this.form.value['size'].lower = 10;
    }
    if (
      this.form.value['price'].upper == null ||
      this.form.value['price'].upper == null
    ) {
      this.form.value['price'].upper = 10000000;
      this.form.value['price'].lower = 10;
    }

    this.form.get('lat').setValue(null);
    this.form.get('lng').setValue(null);
    delete this.form.value['location'];
    this.onSearchClick.emit(this.form.value);
  }

  public onSelectCity() {
    this.form.controls['neighborhood'].setValue(null, { emitEvent: false });
    this.form.controls['street'].setValue(null, { emitEvent: false });
  }
  public onSelectNeighborhood() {
    this.form.controls['street'].setValue(null, { emitEvent: false });
  }

  public getAppearance() {
    return this.variant != 3 ? 'outline' : '';
  }
  public getFloatLabel() {
    return this.variant == 1 ? 'always' : '';
  }
  public getAddress() {
    this.appService
      .getAddress(this.form.get('lat').value, this.form.get('lng').value)
      .subscribe((response) => {
        if (response['results'].length) {
          this.setAddresses(response['results'][0]);
        }
      });
  }
  public setAddresses(result) {
    this.form.get('districts').setValue(null);
    if (result.address_components[0]) {
      var components = result.address_components;
      for (var i = 0; i < components.length; i++) {
        if (
          components[i].types[0] == 'political' ||
          components[i].types[0] == 'administrative_area_level_1'
        ) {
        }
        if (components[i].types[0] == 'administrative_area_level_2') {
          this.form.get('districts').setValue(components[i].long_name);
        }
      }
    }
  }
  public clearSearch() {
    this.isItemAvailable = false;
    this.form.get('location').setValue(null);
  }
}
